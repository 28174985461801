import React from 'react';

function Users(props) {
    return (
      <div className="App">
        <h1>Pass Function AS Prop</h1>
        <button onClick={()=>props.data()}>Click Me !</button>
      </div>
    );
  }
  
  export default Users;