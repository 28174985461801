//import { useState } from 'react';
import React , {useEffect , useState , useMemo , createRef, useRef} from "react";
import logo from './logo.svg';
import './App.css';
import './Style.css';
import Cols from "./Cols";
import Child from "./Child";
// import Users from './Users';
// import Dummy from './Dummy';
// import Prop_function from './Prop_function';
// import Prop_class from './Prop_class';
// import Profile from './Profile';
// import Login from './Login';
// import Render from './Render';
// import Student from './Student';
import Forwardref from "./Forwardref";
import { Button , Table} from 'react-bootstrap';


function App(){
  return(
    <div className="App">
      <h1>Hight order component (HOC)</h1>
      <HOCRED cmp={Counter}/>
      <HOCYELLW cmp={Counter}/>

    </div>
  )
}

function HOCYELLW(Props){
  return <div style={{backgroundColor:"Yellow",color:"#fff",width:"25%",float:"left",fontSize:"20px",padding:"20px"}}>Yellow <Props.cmp/></div>
}
function HOCRED(Props){
  return <div style={{backgroundColor:"red",color:"#fff",width:"25%",float:"left",fontSize:"20px",padding:"20px"}}>Red <Props.cmp/></div>
}
function Counter(){
  let [count , setCount] = useState(0);
  return(
    <div className="App">
      <p>count : {count}</p>
      <Button onClick={()=>setCount(count+1)}>Click</Button>
    </div>
  )
}

// function App(){
//   let [val , Setval] = useState("111");
//   return(
//     <div className="App">
//       <h1>Controlled Component</h1>
//       <input type="text" value={val} onChange={(e)=>Setval(e.target.value)}/>
//       <h1>Value : {val}</h1>
//     </div>
//   )
// }
// Forward Ref
// function App(){
//   let inputref = useRef(null);
//   function updateinput(){
//     inputref.current.value="SHivam";
//   }
//   return(
//     <div className="App">
//      <h1>Forward Ref</h1>
//      <Forwardref ref={inputref}/>
//      <Button onClick={updateinput}>Update Input</Button>
//     </div>
//   )
// }

// Use Ref START
// function App(){
//   let inputref = useRef(null);

//   function refdata(){
//     console.log(inputref.current.value);
//     console.log(inputref.current.style.color="red");
//   }
//   return(
//     <div className="App">
//       <h1>Use Ref</h1>
//       <input type="text" ref={inputref}/>
//       <button onClick={()=>refdata()}>Submit</button>
//     </div>
//   )
// }
// Use Ref END

// class App extends React.Component{

//    constructor(){
//     super();
//     this.inputref = createRef();
//    }
//    componentDidMount(){
//     console.log(this.inputref.current.value="1000");
//    }
//    render(){
//     return(
//       <div className="App">
//         <h1>Ref with Example</h1>
//         <input type="text" ref={this.inputref}/>
//       </div>
//     )
//    }
// }

// UseMemo Hook
// function App(){
//   const [count , setCount] = useState(0);
//   const [itemsss , setItem] = useState(0);

//   const usememedata = useMemo(function countMulti(){
//     console.log('Hekki');
//      return count*5;
//     },[count])

  
//   return(
//    <div className="App">
//      <h1>UseMemo Hook</h1>
//      <h2>count: {count}</h2>
//      <h2>Item: {itemsss}</h2>
//      <h2>Count multiply {usememedata}</h2>
//      <Button onClick={()=> setCount(count+1)}>Update Count</Button> <br></br>
//      <br></br>
//      <Button onClick={()=> setItem(itemsss+10)}>Update Item</Button>
//    </div>
//   );
// }

//
// Pure component start

// class App extends React.PureComponent{

//   constructor(){
//     super();
//     this.state={
//       count:1
//     }
//   }
//   render(){
//     console.log('Helo data');
//     return(
//       <div className="App">
//         <h1>Pure component {this.state.count}</h1>
//         <Button onClick={()=>this.setState({count:this.state.count+1})}>Update</Button>
//       </div>
//     );
//   }
// }

// Pure component end

// function App(){
//   let data = "Shivam Rajpot";
//   function praentAlert(data){
//     alert(data.email);
//   }
//   return(
//     <div className="App">
//      <h1>Send Data Child to Parent Component</h1>
//      <Child name={praentAlert}/>
//     </div>
//   );
// }

// fragment
// function App(){

//   return(
//      <React.Fragment>
//       <h1>fragment in ReactJs</h1>
//       <h1>fragment in ReactJs</h1>    

//       <Table striped>
//       <tbody>
//         <tr>
//           <Cols/>
//         </tr>
//       </tbody>
//       </Table>
//       </React.Fragment>
      
//   );
// }
// fragment End


// function App(){

//   const user = [
//       {name:"Shivam Rajpoot" , email:"Shivam@gmail.com" , phone:'7785070089'},
//       {name:"Satyam Rajpoot" , email:"Satyam@gmail.com" , phone:'7785070090'},
//       {name:"Mahi Rajpoot" , email:"Mahi@gmail.com" , phone:'7785070091'},
//       {name:"Shivani Rajpoot" , email:"Shivani@gmail.com" , phone:'7785070092'},
//       {name:"Ditya Rajpoot" , email:"Ditya@gmail.com" , phone:'7785070093'}
//   ];
//   return(
//     <div className="App">
//       <h1>Reuse component in loop</h1>
//       {
//         user.map((item , i)=>
//         <h1>{item.name}</h1>
//         )
//       }
//     </div>
//   );
// }

// Array listing with map function and nested

// function App(){
//   const student = [
//     {name:"Shivam Rajpoot" , email:"shivam@gmail.com" , phone:"7785070089" , address:[
//       {house_no:"114/30",area:"Vinayakpur",city:"Kanpur",poncode:"208024"},
//       {house_no:"114/31",area:"Vinayakpur",city:"Kanpur",poncode:"208025"},
//       {house_no:"114/32",area:"Vinayakpur",city:"Kanpur",poncode:"208026"}
//     ]},
//     {name:"Satyma Rajpoot" , email:"Satyma@gmail.com" , phone:"7785070090" , address:[
//       {house_no:"114/30",area:"Vinayakpur",city:"Kanpur",poncode:"208024"},
//       {house_no:"114/31",area:"Vinayakpur",city:"Kanpur",poncode:"208025"},
//       {house_no:"114/32",area:"Vinayakpur",city:"Kanpur",poncode:"208026"}
//     ]},
//     {name:"Sundaram Rajpoot" , email:"Sundaram@gmail.com" , phone:"7785070091" , address:[
//       {house_no:"114/30",area:"Vinayakpur",city:"Kanpur",poncode:"208024"},
//       {house_no:"114/31",area:"Vinayakpur",city:"Kanpur",poncode:"208025"},
//       {house_no:"114/32",area:"Vinayakpur",city:"Kanpur",poncode:"208026"}
//     ]},
//     {name:"Ganesh Rajpoot" , email:"Ganesh@gmail.com" , phone:"7785070092" , address:[
//       {house_no:"114/30",area:"Vinayakpur",city:"Kanpur",poncode:"208024"},
//       {house_no:"114/31",area:"Vinayakpur",city:"Kanpur",poncode:"208025"},
//       {house_no:"114/32",area:"Vinayakpur",city:"Kanpur",poncode:"208026"}
//     ]}
//   ];

//   return(
//     <div className="App">
//      <h1>Nested List</h1>
//      <Table striped>
//        <thead>
//         <tr>
//           <th>Sr</th>
//           <th>Name</th>
//           <th>Email</th>
//           <th>Phone</th>
//           <th>Address</th>
//         </tr>
//        </thead>
//        <tbody>
//         {
//           student.map((data, key) =>
//             <tr key={key}>
//               <td>{key+1}</td>
//               <td>{data.name}</td>
//               <td>{data.email}</td>
//               <td>{data.phone}</td>
//               <td>
//               <Table striped>
//                 <tbody>
//                 {
//                   data.address.map((item , kyes)=>
//                      <tr key={kyes}>
//                       <td>{item.house_no}</td>
//                       <td>{item.area}</td>
//                       <td>{item.city}</td>
//                       <td>{item.poncode}</td>
//                      </tr>
//                   )
//                 }
//                 </tbody>
//               </Table>
//                 </td>
//             </tr>
//           )
//         }
//        </tbody>
//      </Table>
//     </div>
//   );
// }


// Array listing with map function

// function App(){
//   const users = ['Shivam' , 'Dinaker' , 'Ranga' , 'Sudhir' , 'Rahul' , 'Satyam'];
//   const student = [
//     {name:"Shivam Rajpoot" , email:"shivam@gmail.com" , phone:"7785070089"},
//     {name:"Satyma Rajpoot" , email:"Satyma@gmail.com" , phone:"7785070090"},
//     {name:"Sundaram Rajpoot" , email:"Sundaram@gmail.com" , phone:"7785070091"},
//     {name:"Ganesh Rajpoot" , email:"Ganesh@gmail.com" , phone:"7785070092"}
//   ];
//   const index = 1;
//   // users.map((item)=>{
//   //   console.log(item)
//   // })
  
//   return(
//     <div className="App">
//        <h1>Array listing with map function</h1>
//        <Table striped bordered hover variant="dark">
//         <thead>
//           <tr>
//             <th>Sr.No</th>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Phone</th>
//           </tr>
//         </thead>
//         <tbody>
//         {
//         student.map((data , key)=>
//             <tr key={key}>
//               <td>{key+index}</td>
//               <td>{data.name}</td>
//               <td>{data.email}</td>
//               <td>{data.phone}</td>
//             </tr>
//         )
//        }
          
//         </tbody>
//        </Table>
       
//        <Table className="table-bordered">
//         <thead>
//           <tr>
//             <th>Sr.No</th>
//             <th>Name</th>
//           </tr>
//         </thead>
//         <tbody>
//         {
//         users.map((data , key)=>
//             <tr key={key}>
//               <td>{key+index}</td>
//               <td>{data}</td>
//             </tr>
//         )
//        }
          
//         </tbody>
//        </Table>
       
//     </div>
//   );
// }

// Bootstrap Example
// function App(){
//   return(
//     <div className="App">
//       <h1 className="fristcss">Bootstrap Example</h1>
//       <Button onClick={()=>alert('Hello')}>Click Me</Button>
//     </div>
//   );
// }

// Styale Example
// function App(){
//   return(
//     <div className="App">
//       <h1 className="fristcss">Style Example 1</h1>
//       <h1 style={{"color":"yellow" , "backgroundColor":"blueviolet"}}>Style Example 2</h1>
//     </div>
//   );
// }
// Use Effect Start
// function App(){
//   const[data,setData] = useState(0);
//   useEffect(()=> {
//     console.log('useEffect');
//   })

//   return(
//     <div className="App">
//       <h1>Use Effect</h1>
//       <p>Totol click {data}</p>
//       <button onClick={()=> setData(data+1)}>Click</button>
//       </div>
//     );
// }

// componentWillUnmount Start
/*class App extends React.Component{
  constructor(){
    super();
    this.state={
        show:true
       }
  }
  render(){
    return(
      <div className='App'>
        {
          this.state.show ? <Student/> : <h1>Else</h1>
        }
        
        <button onClick={()=> this.setState({show:!this.state.show})}>Update</button>
      </div>
    );
  }
}*/
// componentWillUnmount END


// shouldComponentUpdate Start
// class App extends React.Component{
//   constructor(){
//     super();
//     this.state={
//         count:0
//        }
//     console.log('constructor');
//   }
//   componentDidUpdate(){
//     console.log('componentDidUpdate');
//   }
//   shouldComponentUpdate(){
//     console.log('shouldComponentUpdate');
//     return true;
//   }
//   render(){
//     console.log('Render');
//     return(
//       <div className='App'>
//         <h1>Should Component Update</h1>
//         <p>{this.state.count}</p>
//         <button onClick={()=> {this.setState({count:this.state.count+1})}}>Update</button>
//       </div>
//     );
//   }
// }
// shouldComponentUpdate End

// componentDidMount Start
// class App extends React.Component{
//   constructor(){
//     super();
//     this.state={
//         data:"Shivam"
//        }
//     console.log('constructor');
//   }
//   componentDidMount(){
//     console.log('componentDidMount');
//   }
//   render(){
//     console.log('Render');
//     return(
//       <div className='App'>
//         <h1>Component did mount</h1>
//         <p>{this.state.data}</p>
//         <button onClick={()=> this.setState({data:"Satyam"})}>Update</button>
//       </div>
//     );
//   }
// }
// componentDidMount END

// Render Method Start
// function App(){
//   const [name,setNAME] = useState('SHivam');
//   return(
//     <div className="App">
//       <h1>Render life cycle method</h1>
//       <Render name={name}/>
//       <button onClick={()=>setNAME('Satyam')}>Update</button>
//     </div>
//   );
// }
// Render End

// class App extends React.Component{

//   constructor(){
//     super();
//     this.state={
//       data:"Shivam"
//     }
//   }
//   render(){
//     return(
//       <div className="App">
//       <h1>This is class constructor example {this.state.data}</h1>
//       </div>
//     );
//   }
// }
// function App(){

//   function get_data(){
//     alert('Function Called !');
//   }
//    return(
//     <div className="App">
//       <Users data={get_data}/>
//     </div>
//    );
// }

// Form data
//function App(){
//   let [name,setname] = useState();
//   let [email,setEmail] = useState();
//   let [gender,setgender] = useState();

//   function getdata(e){
//     e.preventDefault();
//     console.log(name+','+email+','+gender);
//   }
//   return(
//     <div className="App">
//       <h1>Form example</h1>
//       <form onSubmit={getdata}>
//         <input type="text" placeholder='Enter your name' onChange={(e)=>setname(e.target.value)} className='input_css'/>
//         <input type="email" placeholder='Enter your email' onChange={(e)=>setEmail(e.target.value)} className='input_css'/>
//         <select className='input_css' onChange={(e)=>setgender(e.target.value)}>
//           <option value="">-- Select Gender -- </option>
//           <option value="Male">Male</option>
//           <option value="Female">Female</option>
//           <option value="Other">Other</option>
//         </select>
//         <input type="checkbox"/><span>Term and Condition</span>
//         <button type="Submit" className='btn'>Submit</button>
//       </form>
//      </div>
//   );
// }

// This is hide show example
// function App(){

//   let [status,setData] = useState(false);
//   function show(){
//     setData(true);
//   }
//   function hide(){
//     setData(false);
//   }
//   function toggle(){
//     setData(!status);
//   }

//   return(
//     <div className="App">
//       {
//         status?<h1>Hide Show Example example</h1>:null
//       }
//       <button onClick={()=>hide()}>Hide</button>
//       <button onClick={()=>show()}>Show</button>
//       <button onClick={()=>toggle()}>Toggle</button>
//      </div>
//   );
// }


// This is input example Start
// function App(){

//   let [data,setData] = useState("Shivam Rajpoot");

//   function get_value(val){
//     setData(val.target.value);
//     //console.warn(val.target.value);
//   }
//   return(
//     <div className="App">
//      <h1>Input example</h1>
//      <div>{data}</div>
//      <input type="text" onChange={get_value}></input>
//     </div>
//   );
// }
// End

// class App extends React.Component{
//   constructor(){
//     super();
//     this.state={
//       name : "Raju"
//     }
//   }
//   render(){
//     return (
//           <div className="App">
//             <h1>This is class State Props</h1>            
//             <Prop_class name={this.state.name}/>
//             <button class="btn" onClick={()=>this.setState({name:"Shivam"})}>Change Class Peops</button>
//           </div>
//         );
//   }
// }
// function App() {

//   let [data,setData] = useState("Shivam Rajpoot");
//   let [name,setName] = useState("Raju");

//   function apple(){
//     return(
//       <h3>Shivam</h3>
//     )
//   }
//   function hello(){
//     setData("Anil Rajpoot");
//     alert(data);
//   }

//   function chnage_props(){
//     setName("Shivam");
//   }

//   function chnage_props_cls(){
//     alert('Hello');
//   }


//   return (
//     <div className="App">
//       <h1>Hello Shivam</h1>
//       <Users/>
//       <Dummy/>
//       <Prop_function name={name} lname={"Rajpoot"} />
//       <button class="btn" onClick={()=>chnage_props()}>Change Function Peops</button>
//       <Prop_class name={"Shivam"}/>
//       <button class="btn" onClick={()=>chnage_props_cls()}>Change Class Peops</button>
//       {apple()}
//       <h2>{data}</h2>
//       <button class="btn" onClick={hello}>Click Me</button>
//       <button class="btn" onClick={()=>hello()}>Click Mee</button>
//       <button class="btn" onClick={()=>alert('Hello Data')}>Click Alert</button>
//     </div>
//   );
// }

export default App;
